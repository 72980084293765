import React, { useState } from 'react'
import { AutoComplete } from 'antd'
import axios from '../../../configs/APIConfig/axiosInstance'
import { useTranslation } from 'react-i18next'
import { ReactComponent as Search } from '../../shared-components/svgs/search.svg'

const { Option } = AutoComplete

export default function ResellersDropdown({ onSelect }) {
  const { t } = useTranslation()

  const [keyword, setKeyword] = useState('')
  const [users, setUsers] = useState([])

  const fetchUsers = (keyword) => {
    axios
      .get(`/admin/resellers?${keyword ? `&search=${keyword}` : ''}`)
      .then(({ data: { profiles } }) => setUsers(profiles.data))
  }

  return (
    <div className='services-dropdown'>
      <AutoComplete
        onSelect={(value, option) => {
          onSelect(option.val)
        }}
        placeholder={t('nav.search.ph')}
        value={keyword}
        onChange={(data) => {
          setKeyword(data)
          fetchUsers(data)
        }}
        dropdownAlign={{ offset: [-9, 16] }}
      >
        {keyword &&
          users?.map((ele) => (
            <Option key={ele.id} value={ele.name} val={ele.id}>
              {ele.nickname || `${ele.name} ${ele.lname || ''}`}
            </Option>
          ))}
      </AutoComplete>
      <Search className='icon' />
    </div>
  )
}
