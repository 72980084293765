import { useState, useEffect, useRef } from 'react'
import { Layout } from 'antd'
import { useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { getProfileData } from '../../redux/actions/UserAuth'
import { setNavbarHeight } from '../../redux/actions/Theme'

import AdminViews from '../../views/admin-views'
import Sidenav from '../../components/layout-components/Sidenav'
import HeaderNav from '../../components/layout-components/HeaderNav'
import ModalComponent from '../../features/components/Modal'
import TabFilter from '../../features/components/form/TabFilter'

const { Header, Sider, Content } = Layout

const userTypeTabsFilter = [
  { label: 'Online', key: 'Online' },
  { label: 'Partner', key: 'Partner' },
]

export function AdminLayout() {
  const dispatch = useDispatch()
  const ref = useRef(null)
  const location = useLocation()

  const isChatPage = location.pathname.split('/')[3] === 'Messages'
  const isHomePage = location.pathname.split('/')[3] === 'Home'

  const [navOpen, setNavOpen] = useState(false)
  const [collapsed, setCollapsed] = useState(false)

  useEffect(() => {
    dispatch(getProfileData('admin'))
  }, [dispatch])

  useEffect(() => {
    dispatch(setNavbarHeight(ref.current.clientHeight))
  }, [dispatch, isChatPage])

  return (
    <>
      <Layout id='scroller' className='dashboard-layout'>
        <Sider className={`${navOpen ? 'nav-open' : ''}`} collapsed={collapsed}>
          <Sidenav {...{ navOpen, setNavOpen }} />
        </Sider>
        <Layout id='scroller'>
          <Header
            ref={ref}
            className={`${
              isChatPage || isHomePage ? 'pt-4 pb-0' : 'py-4'
            } px-0 position-sticky top-0`}
            style={{
              boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
              zIndex: '99',
            }}
          >
            <HeaderNav {...{ navOpen, setNavOpen, collapsed, setCollapsed }} />
            {(isChatPage || isHomePage) && (
              <TabFilter filterName='user_type' tabs={userTypeTabsFilter} />
            )}
          </Header>
          <Content>
            <AdminViews />
          </Content>
        </Layout>
      </Layout>
      <ModalComponent />
    </>
  )
}

export default AdminLayout
