import { Tabs } from 'antd'
import { useHistory } from 'react-router-dom'

const TabFilter = ({ filterName, tabs }) => {
  const history = useHistory()

  const searchParams = new URLSearchParams(history.location.search)

  const activeKey = searchParams.get(filterName) || 'Online'

  return (
    <Tabs
      className='mt-3'
      items={tabs}
      activeKey={activeKey}
      onTabClick={(key) => {
        history.push(`${history.location.pathname}?${filterName}=${key}`)
      }}
    />
  )
}

export default TabFilter
