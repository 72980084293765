import { Select } from 'antd'
import { useTranslation } from 'react-i18next'
import { InfoCircleOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'

import './Select.scss'

const { Option } = Select

const SelectDropdown = ({
  required = false,
  name,
  label,
  placeholder,
  disabled,
  options,
  value,
  setValue,
  error,
  isFilter,
  onSearch,
}) => {
  const { t } = useTranslation()
  const history = useHistory()
  return (
    <div className='select-dropdown'>
      {label && (
        <div className='d-flex align-items-center gap-2'>
          <label htmlFor={name}>
            {label} {required && <span className='text-danger'>*</span>}
          </label>
          {error && <InfoCircleOutlined className='text-danger fs-6 mb-2' />}
        </div>
      )}

      <Select
        showSearch
        className='custom-select'
        disabled={disabled}
        id={name}
        placeholder={placeholder}
        value={value}
        optionFilterProp='children'
        filterOption={(input, option) => {
          const originalOption = options.find((o) => o.value === option.value)
          if (!originalOption) return false

          const searchText = `${originalOption.label} ${
            originalOption.extraLabel || ''
          }`.toLowerCase()
          return searchText.includes(input.toLowerCase())
        }}
        onChange={(val) => {
          if (isFilter) {
            const searchParams = new URLSearchParams(history.location.search)
            searchParams.set(name, val)
            searchParams.set('page', 1)
            history.push({
              pathname: history.location.pathname,
              search: searchParams.toString(),
            })
          } else {
            setValue(val)
          }
        }}
        onSearch={onSearch}
        style={{
          backgroundColor: isFilter ? 'rgba(221, 221, 221, 0.20)' : '#FFF',
        }}
      >
        {isFilter && <Option value={''}>{t('All')}</Option>}
        {options?.map((option, i) => (
          <Option key={i} value={option.value}>
            {t(option.label)}{' '}
            {option.extraLabel ? (
              <span className='text-blue fw-semibold'>
                ({option.extraLabel})
              </span>
            ) : null}
          </Option>
        ))}
      </Select>
      <small className='text-danger fs-12'>{error}</small>
    </div>
  )
}

export default SelectDropdown
