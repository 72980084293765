import axios from '../../configs/APIConfig/axiosInstance'
import {
  DIRECTION_CHANGE,
  UPDATE_LANGUAGE_REQUEST,
  UPDATE_LANGUAGE_SUCCESS,
  UPDATE_LANGUAGE_FAIL,
  SET_NAVBAR_HEIGHT,
  SET_DASHBOARD_TITLE,
  CLEAR_ERRORS,
} from '../constants/Theme'

export const updateLanguage = (langData, callback) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_LANGUAGE_REQUEST })

    const { data } = await axios.post('/language/edit', langData)

    dispatch({
      type: UPDATE_LANGUAGE_SUCCESS,
      payload: data,
    })
    callback(data.message)
  } catch (error) {
    dispatch({
      type: UPDATE_LANGUAGE_FAIL,
      payload: error.response,
    })
  }
}

export function onDirectionChange(direction) {
  return {
    type: DIRECTION_CHANGE,
    direction,
  }
}

export const setNavbarHeight = (height) => ({
  type: SET_NAVBAR_HEIGHT,
  payload: height,
})

export const setDashboardTitle = (title) => ({
  type: SET_DASHBOARD_TITLE,
  payload: title,
})

// Clear Errors
export const clearErrors = () => async (dispatch) => {
  dispatch({
    type: CLEAR_ERRORS,
  })
}