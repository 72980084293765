import Cookies from 'js-cookie'
import {
  DIRECTION_CHANGE,
  UPDATE_LANGUAGE_REQUEST,
  UPDATE_LANGUAGE_SUCCESS,
  UPDATE_LANGUAGE_FAIL,
  SET_NAVBAR_HEIGHT,
  SET_DASHBOARD_TITLE,
  CLEAR_ERRORS
} from '../constants/Theme'

const language = Cookies.get('i18next')

const initTheme = {
  direction: language === 'ar' ? 'rtl' : 'ltr',
  navbarHeight: 0,

}

const theme = (state = initTheme, action) => {
  switch (action.type) {
    case DIRECTION_CHANGE:
      return {
        ...state,
        direction: action.direction,
      }
    case SET_NAVBAR_HEIGHT:
      return {
        ...state,
        navbarHeight: action.payload,
      }
    case SET_DASHBOARD_TITLE:
      return {
        ...state,
        dashboardTitle: action.payload,
      }
    default:
      return state
  }
};

export const languageReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_LANGUAGE_REQUEST:
      return {
        ...state,
        loading: true,
      }

    case UPDATE_LANGUAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        isUpdated: true,
        message: action.payload.message,
      }

    case UPDATE_LANGUAGE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      }

    default:
      return state
  }
}

export default theme